.choseCompany-main .main-box {
  margin-top: 100px;
  display: flex;
  justify-content: space-around;
}
.choseCompany-main .main-box .box {
  width: 400px;
  height: 400px;
  line-height: 400px;
  text-align: center;
  cursor: pointer;
  border: 2px solid black;
  box-sizing: border-box;
}
